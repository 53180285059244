<template>
  <div class="contact">
    <div class="section-vision"></div>
    <div class="section-info row-center">
      <div class="section-box info">
        <div class="info-layer">
          <div class="info-head">
            <div class="icon"></div>
            <h1 class="title">
              <strong>合作伙伴</strong>
            </h1>
          </div>
          <div class="info-body">
            <div class="item">
              <strong class="label">联系电话</strong>
              <a class="value" href="tel:020-32640047">020-32640047</a>
            </div>
            <div class="item">
              <strong class="label">联系邮箱</strong>
              <a class="value" href="mailto:admin@xianweiyeye.com">admin@xianweiyeye.com</a>
            </div>
            <div class="item">
              <strong class="label">联系地址</strong>
              <span class="value">广东省广州市海珠区新滘中路（海珠同创汇）西五街66号之一整栋</span>
            </div>
          </div>
        </div>
        <div class="info-foot">
          <img src="@/assets/contact/contact1.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .contact {
    .section-vision {
      background: url("../../assets/contact/vision-m.jpg") no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 4rem;
    }
    .section-info {
      .info {
        padding: 0.6rem;
        box-sizing: border-box;
        &-layer {
          font-size: 0;
        }
        &-head {
          margin-bottom: 0.6rem;
          .icon {
            display: none;
          }
          .title {
            display: flex;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.36rem;
              color: #e76d01;
              line-height: 0.5rem;
            }
          }
        }
        &-body {
          display: flex;
          flex-wrap: wrap;
          .item {
            display: flex;
            flex-direction: column;

            min-width: 50%;
            .label,
            .value {
              font-family: PingFangSC-Medium;
              font-size: 0.24rem;
              color: #e76d01;
              line-height: 0.6rem;
              text-align: left;
            }
            .value {
              font-weight: 400;
              color: #3a3a3a;
            }
          }
        }
        &-foot {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .contact {
    .section-vision {
      background: url("../../assets/contact/vision.jpg") no-repeat center center;
      background-size: cover;
      min-width: 1190px;
      height: 500px;
    }
    .section-info {
      background: #fff;
      padding: 150px 0 230px;
      box-sizing: border-box;
      .info {
        display: flex;
        justify-content: space-between;
        &-head {
          padding-top: 13px;
          box-sizing: border-box;
          .icon {
            background: url("../../assets/contact/contact0.png") no-repeat center;
            background-size: 100%;
            width: 209px;
            height: 45px;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 0 60px;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
            }
          }
        }
        &-body {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .item {
            display: flex;
            flex-direction: column;
            min-width: 50%;
            margin-top: 55px;
            .label,
            .value {
              font-family: PingFangSC-Medium;
              font-size: 24px;
              color: #e76d01;
              line-height: 60px;
              text-align: left;
            }
            .value {
              color: #3a3a3a;
              font-weight: 400;
            }
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 460px;
          height: 646px;
          margin-left: 110px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
